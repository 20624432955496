<template>
    <div>
        <GhLocation
            style="margin-top: -3px;"
            :dataLocation="{showButton: false, secondWindow: true, showLoader:'OK', text: $t('company'), img: require('@/assets/gh_new_dessign/floating.svg')}"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{add:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'register',
                    title: $t('company'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <div v-if="haveData">
                        <component :is="edit"></component>
                    </div>
                </template>
            </GhNoDropSelector>
            <div  class="centered_footer" style="margin-top: 8px; margin-bottom: 10px">
                <GhButton
                    :datainput="{
                        id: 'btn_save',
                        text: $t('save')
                    }"
                    type="submit"
                    @click="modifyBusiness"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('back')
                    }"
                    @click="changeState"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import edit from "@/view/business/template/edit.vue";
    import GhButton from "fe-gh-button-lib";
    export default {
        name: 'float_company',
        components: {
            GhButton,
            GhLocation,
            GhNoDropSelector
        },
        data(){
            return {
                edit: edit,
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getBusinessData', {}, {root: true});
            await this.$store.dispatch('getSocialReason', '', {root: true});
            await this.$store.dispatch('getCountry', '', {root: true});
            if(this.getterCompanyData !== undefined && this.getterCompanyData.country !== undefined){
                await this.$store.dispatch('getProvinceByCountry', {
                    country: Object.keys(this.getterCompanyData.country)[0],
                }, {root: true});
            }
            await this.$store.dispatch('getActivity', '', {root: true});
            await this.$store.dispatch('getPosition', '', {root: true});
            await this.$store.dispatch('getNumEmployers', '', {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods: {
            changeState(){
                this.Global.windowClose();
            },
            async modifyBusiness(){
                if(this.Global.checkRequired('form_business') == 'OK') {
                    const formData = new FormData(document.getElementById('form_business'));
                    this.$store.dispatch('setBusinessData', {formData: [...formData]},{root:true});

                    this.Global.windowClose();
                }
            }
        }
    }
</script>